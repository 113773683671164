import { useEffect, useState } from 'react';

const ZOOMER_ID = 'item-zoomer';

const Zoomer = ({ zoomFunc, imageRef, scale }) => {
	const [coords, setCoords] = useState({});

	useEffect(() => {
		document.addEventListener('centerAt', function ({ detail: jsonCoords }) {
			try {
				const coords = JSON.parse(jsonCoords);
				setCoords(coords);
			} catch (e) {
				console.error('Failed to center zoom', e);
			}
		});
	}, []);

	useEffect(() => {
		if (!coords || !imageRef || !imageRef.current) return;
		zoomFunc(
			-coords.x * (imageRef.current.clientWidth * scale) +
				window.innerWidth / 2,
			-coords.y * (imageRef.current.clientHeight * scale) +
				window.innerHeight / 2,
			scale
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coords, imageRef]);

	return (
		<div
			id={ZOOMER_ID}
			style={{
				left: `${coords ? coords.x * 100 : 0}%`,
				top: `${coords ? coords.y * 100 : 0}%`,
				width: 1,
				height: 1,
			}}
		/>
	);
};

export default Zoomer;
